<template>
  <div class="welcome">
    Welcome coder, its all yours, you can rename app folder to app sepcific name and put app related views within them.
    In short, do whatever you like.
  </div>
</template>

<script>
export default {
  name: 'Welcome',
}
</script>

<style scoped>

</style>
